import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: fixed;
	background-color: #262626;
	left: 0;
	right: 0;
	top: 70px;
	padding: 1em;
	height: calc(100% - 70px);
	overflow-y: auto;
	overflow-x: hidden;

	&.topbar {
		top: 100px;
		height: calc(100% - 100px);
	}

	.drop-down-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 1em;
		transition: transform 0.3s ease-in-out;
		height: calc(100% - 50px);

		&.first-step-translate {
			transform: translateX(calc(-100% - 1em));
		}

		&.second-step-translate {
			transform: translateX(calc(-200% - 2em));
		}

		.gender-zone-buttons {
			padding: 0.3em;
			border-radius: 0.5em;
			display: flex;
			width: 100%;
			background-color: transparent;
			gap: 2em;

			.gender-button {
				background: ${vars.backgroundButtonGradient};
				text-decoration: none;
				color: #ffffff;
				padding: 0.3em 0.5em;
				border-radius: 5px;
				font-size: 20px;
				transition: background-color 200ms ease;
				width: 100%;
				text-align: center;
				opacity: 0.5;

				&::selection {
					background-color: transparent;
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}

				&.active {
					opacity: 1;

					&:hover {
						background-color: #cfb799;
					}
				}

				&.female {
					&::before {
						content: url('/images/icon-gender-women-white.svg');
						width: 20px;
						height: 10px;
						margin: 0 5px 0 0;
						transform: translateY(3px);
						display: inline-block;
					}
				}
				&.male {
					&::before {
						content: url('/images/icon-gender-man-white.svg');
						width: 20px;
						margin: 0 5px 0 0;
						transform: translateY(3px);
						display: inline-block;
					}
				}
			}
		}

		.pages {
			padding: 1em 1em 0;
			margin: 1.5em 0;
			border-top: 2px solid rgb(62, 62, 62);
			display: flex;
			flex-flow: column;
			gap: 1em;
			height: auto;
			overflow-y: auto;

			.pages-link {
				display: flex;
				flex-direction: column;

				.pages-link-about-links,
				.pages-link-locations-links {
					display: flex;
					flex-direction: column;
					background-color: rgb(49, 49, 49);
					border-radius: 0.5em;
					padding: 0.4em 0;
					margin-bottom: 2em;

					.pages-link {
						&-anchor {
							padding: 0.6em 1.6em;
							font-size: 14px;
							width: 100%;
						}
					}
				}

				&-anchor {
					color: white;
					text-decoration: none;
					font-size: 17px;
					display: flex;
					align-items: center;
					gap: 1.5em;
					-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

					&::selection {
						background-color: transparent;
					}

					img {
						&::selection {
							background-color: transparent;
						}
					}

					.mobile-icon {
						width: 34px;
						height: 34px;
						display: flex;
						place-content: center;
						place-items: center;
						border: 2px solid ${vars.colorTextPrimary};
						border-radius: 10px;
						padding: 4px;
					}

					.arrow-icon {
						margin: 0 1em 0 auto;
					}
				}

				&.highlighted {
					display: inline-block;

					.pages-link-anchor {
						gap: 0;
					}

					a {
						padding: 0.5em 0.5em 0.5em;
						background-color: #ba9448;
						border-radius: 0.5em;
					}
				}
			}
		}

		.offer-button {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 0.6em;
			background-color: ${vars.colorGreenDarker};
			color: ${vars.colorWhite};
			text-decoration: none;
			padding: .75em 2.35em;
			border-radius: 10px;
			transition: transform 0.2s ease;
			width: fit-content;
			margin: 2em auto;
			order: 2;

			.offer-button-text {
				text-decoration: none;
				color: ${vars.colorWhite};
				font-size: 1.2em;
			}

			&:hover {
				transform: scale(1.05);
			}

			&:visited {
				color: ${vars.colorWhite};
				text-decoration: none;
			}
		}

		.pages-link-mobile {
			display: flex;
			align-items: center;
			flex-direction: column;
			width: fit-content;

			.pages-link-anchor {
				padding: 0.6em !important;
				margin-left: 1.5em;

				&::after {
					content: '';
					display: inline-block;
					width: 10px;
					height: 10px;
					background: url('/images/home/arrow-down-nav.svg') no-repeat;
				}

				&.active {
					background-color: #ba9449;
					border-radius: 0.6em;
				}
			}

			.subsection-element {
				color: #fff;
				font-size: 13px;
				padding: 0.5em;
				white-space: nowrap;
				text-decoration: none;
				border-radius: 0.6em;
			}

			.subsection-underline {
				text-decoration: underline;
				color: #fff;
				padding: 0.5em;
			}
		}

		.pages-link-dropdown {
			margin-left: 2em;
		}
	}

	.second-step {
		position: absolute;
		top: 0;
		left: calc(200% + 2em);
		width: 95%;
		height: 100%;
		font-size: 17px;
		color: #fff;
		padding: 2em 0.5em 0 0.5em;
		border-top: 2px solid rgb(62, 62, 62);
		overflow: hidden;
		overflow-y: scroll;

		display: flex;
		flex-flow: column nowrap;

		&__header {
			display: flex;
			place-items: center;
			gap: 2em;
			margin: 0 0 1.2em 0;

			.active-title {
				color: #ba9448;
			}
		}

		&__mobile {
			width: 34px;
			height: 34px;
			display: flex;
			place-content: center;
			place-items: center;
			border: 2px solid ${vars.colorTextPrimary};
			border-radius: 10px;
			padding: 4px;

			&--active {
				background-color: #ba9448;

				img {
					filter: brightness(0) invert(1);
				}
			}
		}
	}

	.step-back-button {
		margin: auto 0 0 0;
		display: flex;
		gap: 1em;
		color: #ba9448;
		padding: 1em 0.7em;
		font-size: 17px;

		.arrow {
			transform: scale(-1);
		}
	}

	.info-line {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100% - 4em);
		max-height: 58px;
		height: 58px;

		.info-line-nav-wrapper {
			display: flex;
			justify-content: center;
			border-top: 2px solid rgb(62, 62, 62);
			padding: 1.5em 0 1em 0;

			.info-line-nav-content {
				display: flex;
				gap: 1em;
				font-size: 16px;

				* {
					&::selection {
						background-color: transparent;
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.mobileM}) {
		.drop-down-wrapper .offer-button {
			padding: .75em 1.45em;

			.offer-button-text {
				font-size: 1.1em;
				text-align: center;
			}
		}
	}

	@media all and (max-height: 700px) {
		.drop-down-wrapper {
			.pages {
				margin: 1em 0;
				gap: 0.8em;
			}

			.pages-link {
				&-anchor {
					gap: 1em;
					font-size: 16px;
				}
			}
		}

		.drop-down-wrapper .offer-button {
			margin: 1.5em auto;
			padding: .6em 1.8em;

			.offer-button-text {
				font-size: 1em;
			}
		}

		.step-back-button {
			padding: 0.7em;
		}
	}

	@media all and (max-height: 600px) {
		.drop-down-wrapper {
			padding: 0.5em;
		}

		.pages {
			margin: 0.5em 0;
			gap: 0.5em;
		}

		.drop-down-wrapper .offer-button {
			margin: 1em auto;
		}
	}
`;
