import Component from './component';

Component.defaultProps = {
    children: null,
    topbarScrollEffect: true,
    forceVariant: null,
    isBannerVisible: false,
    isLandingPage: false,
};

export default Component;
