import { FunctionComponent, useContext } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import moment from 'moment-timezone';

import { Locales } from 'types/locale';
import { Routes } from 'types/routes';

import { imageResizer } from 'utils/image';
import { getLocaleConfig } from 'utils/locale';
import { withVariables } from 'utils/string';

import LocaleContext from 'components/context/Locale';
import MarketContext from 'components/context/Market';
import FooterSection from 'components/layout/FooterSection';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import Logo from 'components/layout/Logo';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutFooter: FunctionComponent<Props> = ({ }) => {
    const yearNow = moment().format('YYYY');
    const { publicRuntimeConfig } = getConfig();
    const router = useRouter();

    const localeContext = useContext(LocaleContext);
    const marketContext = useContext(MarketContext);
    const translations = localeContext?.translations?.navigation;
    const lightLogo = getLocaleConfig()[localeContext.locale]?.defaultLogoLightUrl;
    const locations = marketContext?.locations;

    const isLandingPageView = router.pathname === Routes.LandingPages;
    const landingPagesWithDifferentPhoneNumber = [
        publicRuntimeConfig.LP_LOCATIONS_ROUTE,
    ];
    const contactBoxPhoneTranslations = landingPagesWithDifferentPhoneNumber.includes(router?.asPath)
        ? translations?.footerTranslations?.contact?.contactBoxPhoneLandingPage
        : translations?.footerTranslations?.contact?.contactBoxPhone;

    return (
        <StyledComponent className="public-layout-footer">
            <div className="top">
                <LayoutContainer>
                    <div className="col col-left">
                        <div className="contact-headline">
                            {translations?.footerTranslations?.contact?.headline}
                        </div>
                    </div>
                    <div className="col col-right">
                        <div className="contact-box">
                            <div className="contact-box-col contact-box-col-icon">
                                <img
                                    className="contact-box-icon"
                                    src="/theme/images/tel.svg"
                                    alt={translations?.footerTranslations?.contact?.icon?.alt}
                                    width="32px"
                                    height="35px"
                                    loading="lazy"
                                />
                            </div>
                            <div className="contact-box-col contact-box-col-info">
                                <span className="contact-box-label">
                                    {contactBoxPhoneTranslations?.label}
                                </span>
                                <a
                                    className="contact-box-value"
                                    href={`tel: ${contactBoxPhoneTranslations?.value}`}
                                >
                                    {contactBoxPhoneTranslations?.value}
                                </a>
                            </div>
                        </div>
                        <div className="contact-box">
                            <div className="contact-box-col contact-box-col-info">
                                <div className="contact-box-label">
                                    {translations?.footerTranslations?.contact?.contactBoxInfo?.label}
                                </div>
                                <div className="contact-box-col-info-value">
                                    {translations?.footerTranslations?.contact?.contactBoxInfo?.value}
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutContainer>
            </div>
            {!isLandingPageView && (
                <>
                    <div className="bottom">
                        <LayoutContainer>
                            <div className="col col-nav">
                                {translations?.footerMain.label && (
                                    <div
                                        className="nav-headline"
                                        dangerouslySetInnerHTML={{ __html: translations?.footerMain.label }}
                                    />
                                )}
                                <FooterSection {...translations?.footerMain} />
                            </div>
                            {translations?.footerPriceLists && Array.isArray(translations?.footerPriceLists.elements) && translations?.footerPriceLists.elements.length > 0 && (
                                <div className="col col-nav">
                                    {translations?.footerPriceLists.label && (
                                        <div
                                            className="nav-headline"
                                            dangerouslySetInnerHTML={{ __html: translations?.footerPriceLists.label }}
                                        />
                                    )}
                                    <FooterSection {...translations?.footerPriceLists} />
                                </div>
                            )}
                            {translations?.footerBlog && Array.isArray(translations?.footerBlog.elements) && translations?.footerBlog.elements.length > 0 && (
                                <div className="col col-nav">
                                    {translations?.footerBlog.label && (
                                        <div
                                            className="nav-headline"
                                            dangerouslySetInnerHTML={{ __html: translations?.footerBlog.label }}
                                        />
                                    )}
                                    <FooterSection {...translations?.footerBlog} />
                                </div>
                            )}
                            {translations?.footerContact && Array.isArray(translations?.footerContact.elements) && translations?.footerContact.elements.length > 0 && (
                                <div className="col col-nav">
                                    {translations?.footerContact.label && (
                                        <div
                                            className="nav-headline"
                                            dangerouslySetInnerHTML={{ __html: translations?.footerContact.label }}
                                        />
                                    )}
                                    <FooterSection {...translations?.footerContact} />
                                </div>
                            )}
                        </LayoutContainer>
                    </div>
                    <div className="locations">
                        <LayoutContainer size={Sizes.Small}>
                            <p className="locations-headline">
                                {translations?.footerTranslations?.locations?.label}
                            </p>
                            <div className="locations-container">
                                {Array.isArray(locations) && locations.length > 0 && locations
                                    ?.filter(location => location.isVirtual !== true)
                                    ?.map(({ id, name, locale }) => (
                                        <a
                                            className="location"
                                            key={id}
                                            href={withVariables(Routes.PublicLocation, { slug: locale?.slug })}
                                        >
                                            {name}
                                        </a>
                                    ))}
                            </div>
                        </LayoutContainer>
                    </div>
                </>
            )}
            <div className="additional-info">
                <LayoutContainer>
                    <a
                        className="link"
                        href="/"
                    >
                        <Logo logo={lightLogo} />
                    </a>
                    <div className="address-box">
                        <img
                            className="address-box-icon"
                            src="/theme/images/address.svg"
                            loading="lazy"
                            alt={translations?.footerTranslations?.additionalInfo?.addressBoxIcon?.alt}
                            width="40px"
                            height="40px"
                        />
                        <div className="address-box-content">
                            <div className="address-box-label">
                                {translations?.footerTranslations?.additionalInfo?.address?.label}
                            </div>
                            <div
                                className="address-box-value"
                                dangerouslySetInnerHTML={{ __html: translations?.footerTranslations?.additionalInfo?.address?.value }}
                            />
                        </div>
                    </div>
                    <div className="socials">
                        <div className="socials-label">
                            {translations?.footerTranslations?.additionalInfo?.socials?.label}
                        </div>
                        <div className="socials-icons">
                            {localeContext.config[localeContext.locale].defaultLocale === Locales.Pl && (
                                <a
                                    href={publicRuntimeConfig.YOUTUBE_LINK}
                                    target="blank"
                                >
                                    <img
                                        className="icon"
                                        src="/theme/images/yt.svg"
                                        loading="lazy"
                                        alt={translations?.footerTranslations?.additionalInfo?.socials?.icons?.youtube?.alt}
                                    />
                                </a>
                            )}
                            <a
                                href={publicRuntimeConfig.TIKTOK_LINK}
                                target="blank"
                            >
                                <img
                                    className="icon"
                                    src="/theme/images/tt.svg"
                                    loading="lazy"
                                    alt={translations?.footerTranslations?.additionalInfo?.socials?.icons?.tiktok?.alt}
                                />
                            </a>
                            <a
                                href={publicRuntimeConfig.FACEBOOK_LINK}
                                target="blank"
                            >
                                <img
                                    className="icon"
                                    src="/theme/images/fb.svg"
                                    loading="lazy"
                                    alt={translations?.footerTranslations?.additionalInfo?.socials?.icons?.facebook?.alt}
                                />
                            </a>
                            <a
                                href={publicRuntimeConfig.INSTAGRAM_LINK}
                                target="blank"
                            >
                                <img
                                    className="icon"
                                    src="/theme/images/insta.svg"
                                    loading="lazy"
                                    alt={translations?.footerTranslations?.additionalInfo?.socials?.icons?.instagram?.alt}
                                />
                            </a>
                        </div>
                    </div>
                </LayoutContainer>
            </div>
            <div className="logos">
                <LayoutContainer>
                    {localeContext.config[localeContext.locale].defaultLocale === Locales.Pl
                        ? (
                            <img
                                className="footer-logos"
                                src={imageResizer('/theme/images/logos.png', { width: 1300 })}
                                srcSet={imageResizer('/theme/images/logos.png', { width: 1300 }, true)}
                                loading="lazy"
                                alt={translations?.footerTranslations?.logos?.footerLogos?.alt}
                            />
                        )
                        : (
                            <img
                                className="footer-logos"
                                src="/theme/images/footer-par.svg"
                                loading="lazy"
                                alt={translations?.footerTranslations?.logos?.footerLogos?.alt}
                                width="1140px"
                                height="40px"
                            />
                        )
                    }
                </LayoutContainer>
            </div>
            <div className="super-bottom">
                <LayoutContainer>
                    <p className="copyright">
                        {`© Depilacja.pl ${yearNow}`}
                    </p>
                </LayoutContainer>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutFooter;
