import { FunctionComponent, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import Location from 'models/Location';
import ProductCategory from 'models/ProductCategory';

import { withVariables } from 'utils/string';

import MarketContext from 'components/context/Market';

import BlogsLinks from '../BlogsLinks';

import LinkItem from './PagesLinksItem';
import StyledComponent from './styles';
import { MenuState, Props, SectionClassNames } from './types';

const initialMenuState: MenuState = {
    visible: false,
    hover: {
        link: null,
        dropDown: null,
        subMenu: null,
        category: null,
        city: null,
    },
};

const PublicLayoutNavigationDesktopPagesLinks: FunctionComponent<Props> = ({ pages }) => {
    if (pages.length <= 0) return null;

    const [menuState, setMenuState] = useState<MenuState>(initialMenuState);
    const { productCategories, locations } = useContext(MarketContext);

    const visibleProductCategories = useMemo(() =>
        productCategories?.filter(category => category.showInMenu !== false) || [],
    [productCategories]);

    const locationsByCategory = useMemo(() => {
        if (!(locations?.length && visibleProductCategories?.length)) return {};

        const locationMap: {[categoryId: string]: Location[]} = {};

        visibleProductCategories.forEach(category => {
            locationMap[category.id] = [];
        });

        locations.forEach(location => {
            location.productCategories?.forEach(category => {
                if (locationMap[category.id]) {
                    const hasVisibleLocale = location.locales?.some(
                        locale =>
                            locale.productCategoryId === category.id &&
                            locale.showInCategoryMenu !== false
                    );

                    if (hasVisibleLocale) {
                        locationMap[category.id].push(location);
                    }
                }
            });
        });

        return locationMap;
    }, [locations, visibleProductCategories]);

    const setVisible = useCallback((visible: boolean): void =>
        setMenuState(prevState => ({ ...prevState, visible })), []);

    const onHover = useCallback((obj: Partial<MenuState['hover']>): void =>
        setMenuState(prevState => ({
            ...prevState,
            hover: { ...prevState.hover, ...obj },
        })), []);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!menuState.visible) {
            timeout = setTimeout(() => setMenuState(initialMenuState), 600);
        }
        return () => clearTimeout(timeout);
    }, [menuState.visible]);

    const renderLocationsByCity = useCallback((locations: Location[], categorySlug?: string, categoryId?: string, categoryName?: string): any[] => {
        const locationsByCity: { [cityName: string]: any } = {};

        locations.forEach(location => {
            const visibleLocales = location.locales?.filter(
                locale => locale.showInCategoryMenu !== false
            );

            if (!visibleLocales?.length) return;

            const cityName = location.city?.name || 'Inne';
            if (!locationsByCity[cityName]) {
                locationsByCity[cityName] = [];
            }
            locationsByCity[cityName].push(location);
        });

        return Object.entries(locationsByCity).map(([cityName, cityLocations]) => ({
            key: cityName,
            title: cityName,
            subsections: cityLocations.map(location => {
                const locationLocale =
                    location.locales?.find(
                        locale => locale.productCategoryId === categoryId && locale.showInCategoryMenu !== false
                    ) ||
                    location.locales?.find(locale => locale.showInCategoryMenu !== false) ||
                    location.locales?.[0];

                return {
                    key: location.id,
                    title: `${location.name} - ${categoryName || ''}`,
                    subTitle: location.displayAddress,
                    href: withVariables(Routes.PublicCategoryLocation, {
                        categorySlug,
                        slug: locationLocale?.slug,
                    }),
                    query: { productCategoryId: categoryId },
                };
            }),
        }));
    }, []);

    const locationSections = useMemo(() => {
        if (!visibleProductCategories?.length) {
            return [];
        }

        return visibleProductCategories
            .map(category => {
                const categoryLocations = locationsByCategory[category.id] || [];
                const locationSubsections = categoryLocations.length
                    ? renderLocationsByCity(
                        categoryLocations,
                        category.slug,
                        category.id,
                        category.nameDisplay,
                    )
                    : [];

                if (locationSubsections.length === 0) {
                    return null;
                }

                return {
                    key: category.id,
                    title: category.nameDisplay,
                    subsections: [
                        ...locationSubsections,
                        {
                            key: `all-${category.id}`,
                            title: 'Zobacz wszystkie lokalizacje',
                            highlighted: true,
                            href: withVariables(Routes.PublicLocations, {}),
                            query: { productCategoryId: category.id },
                        },
                    ],
                };
            })
            .filter(Boolean);
    }, [visibleProductCategories, locationsByCategory, renderLocationsByCity]);

    const renderSubSections = (subsections: any[], className: SectionClassNames, categoryId?: string): ReactNode =>
        Array.isArray(subsections) && (
            <ul className={className}>
                {subsections?.map(subsection => renderSubSectionElement(subsection, className, categoryId))}
            </ul>
        );

    const renderSubSectionElement = (subsection, className: SectionClassNames, categoryId?: string): ReactNode => {
        const canHovered = className === SectionClassNames.Subsection;
        const visiblePin = className === SectionClassNames.SubSubSection;
        const uniqueKey = categoryId ? `${categoryId}-${subsection.key}` : subsection.key;

        return (
            <li
                key={uniqueKey}
                className={classnames(`${className}__element`, {
                    'golden': Boolean(subsection.highlighted),
                    'hover': canHovered && (
                        categoryId
                            ? menuState?.hover?.subMenu === uniqueKey && menuState?.hover?.category === categoryId
                            : menuState?.hover?.subMenu === subsection.key
                    ),
                })}
                onMouseEnter={() => canHovered && onHover({
                    subMenu: uniqueKey,
                    category: categoryId,
                })}
            >
                <div className={`${className}__element-content`}>
                    <LinkItem
                        key={uniqueKey}
                        href={subsection.href}
                        visiblePin={visiblePin}
                        title={subsection.title}
                        subTitle={subsection?.subTitle}
                        className={`${className}__item`}
                        query={subsection.query}

                    />
                </div>
                {/* Render child subsections if any */}
                {renderSubSections(subsection.subsections, SectionClassNames.SubSubSection, categoryId)}
            </li>
        );
    };

    const renderDropdownContent = (page) => {
        if (page.key === 'locations' && page.dropDown.dynamicContent) {
            return generateLocationSections().map((section, idx) => (
                <li
                    className={classnames('drop-down__list', {
                        'hover': menuState?.hover?.dropDown === section.key &&
                     menuState?.hover?.category === section.key,
                    })}
                    key={section.key || idx}
                    onMouseEnter={() => onHover({
                        dropDown: section.key,
                        category: section.key,
                        subMenu: null,
                    })}
                >
                    <LinkItem
                        key={section.key || idx}
                        className='drop-down__item'
                        href={section.href}
                        title={section.title}
                    />
                    <img
                        src="/images/home/arrow-right-nav.svg"
                        alt="icon"
                        width="10px"
                        height="10px"
                    />
                    {renderSubSections(section?.subsections, SectionClassNames.Subsection, section.key)}
                </li>
            ));
        } else {
            return page.dropDown.sections.map((section, idx) => (
                <li
                    className={classnames('drop-down__list', {
                        'hover': menuState?.hover?.dropDown === section.key,
                    })}
                    key={section.key || idx}
                    onMouseEnter={() => onHover({ dropDown: section.key, subMenu: null })}
                >
                    <LinkItem
                        key={section.key || idx}
                        className='drop-down__item'
                        href={section.href}
                        title={section.title}
                    />
                    <img
                        src="/images/home/arrow-right-nav.svg"
                        alt="icon"
                        width="10px"
                        height="10px"
                    />
                    {renderSubSections(section?.subsections, SectionClassNames.Subsection, section.key)}
                </li>
            ));
        }
    };

    const renderSubmenuContent = (page) => {
        if (page.subMenu.dynamicContent) {
            switch (page.key) {
                case 'otherTreatmets':
                    return visibleProductCategories.map((productCategory: ProductCategory) => (
                        <LinkItem
                            key={productCategory.id}
                            className='drop-down__item'
                            href={productCategory.link || '/' + productCategory.slug}
                            title={productCategory.nameDisplay}
                        />
                    ));
                default:
                    return (
                        <BlogsLinks
                            firstVisibleCategory={page.subMenu.firstVisibleCategory}
                            categories={page.subMenu.categories}
                        />
                    );
            }
        } else {
            return page?.subMenu?.sections.map((section, idx: number) => (
                <LinkItem
                    key={section.key || idx}
                    className='drop-down__item'
                    href={section.href}
                    title={section.title}
                />
            ));
        }
    };

    const generateLocationSections = useCallback((): any[] => {
        return locationSections;
    }, [locationSections]);

    return (
        <StyledComponent className="public-layout-navigation-desktop-pages-links">
            <nav className="links-group">
                {pages.map((page, idx: number) => (
                    <div
                        key={`${page.key}-${idx}`}
                        className={classnames('link', {
                            'link--highlighted': page.buttonProps?.highlighted,
                            'has-sub-menu': page.subMenu,
                            'has-drop-down': page.dropDown,
                            'hover': page.key === menuState?.hover?.link,
                        })}
                        onMouseEnter={() => {
                            setVisible(true);
                            onHover({ link: page.key });
                        }}
                        onMouseLeave={() => setVisible(false)}
                    >
                        {/* Main link */}
                        <LinkItem
                            key={`${page.key}-${idx}-${idx}`}
                            boldedAttribute={true}
                            className='link__anchor'
                            href={page?.buttonProps?.href}
                            title={page?.buttonProps?.children}
                        />

                        {/* Submenu if present */}
                        {page.subMenu && (
                            <>
                                <div className="link__chevron">
                                    <img
                                        src="/images/home/arrow-down-nav.svg"
                                        alt="icon"
                                        width="10px"
                                        height="10px"
                                    />
                                </div>
                                <div className={classnames('drop-down', {
                                    'drop-down': page.subMenu.dynamicContent,
                                })}
                                >
                                    {renderSubmenuContent(page)}
                                </div>
                            </>
                        )}

                        {/* Dropdown if present */}
                        {page.dropDown && (
                            <>
                                <div className="link__chevron">
                                    <img
                                        src="/images/home/arrow-down-nav.svg"
                                        alt="icon"
                                        width="10px"
                                        height="10px"
                                    />
                                </div>
                                <ul className="drop-down">
                                    {renderDropdownContent(page)}
                                </ul>
                            </>
                        )}
                    </div>
                ))}
            </nav>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationDesktopPagesLinks;