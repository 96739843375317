export interface Props {
	pages: any;
}

export enum SectionClassNames {
	Subsection = 'subsection',
	SubSubSection = 'sub-subsection',
}

export interface MenuState {
	visible: boolean;
	hover: {
		link: string;
		dropDown: string;
		subMenu: string;
        category: string;
        city: string;
	};
}
